import React        from 'react';
import _            from "lodash";
import FieldGroup   from '@components/fieldGroup';
import User         from "@entities/user";
import Contact      from "@entities/contact";
import Tenant       from "@entities/tenant";
import Form         from '@cBehaviour/form';
import FInput       from '@components/input';
import T            from '@cBehaviour/i18n';
import Button       from '@cComponents/button';
import Display      from '@cComponents/displayIf';
import Field        from '@cComponents/old/field';
import Filter       from "@cComponents/filter";
import FormError    from "@cComponents/formError";
import Input        from '@cComponents/old/input';
import NewInput     from "@cComponents/input";
import Acl          from "@cBehaviour/acl";
import Wizard       from "@cComponents/wizard";
import CategoryItem from "./item";
import Application  from "@uBehaviour/application";
import Query        from "@uLib/query";


import './form.css';
import Nudge from '@common/components/nudge';

class CategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this._preload      = this._preload.bind(this);
    this._presubmit    = this._presubmit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  _onFormChange(value, origin) {
    if (origin === "type") {
      value.citizen = value.type === "publicSpace"
        ? value.citizen
          ? value.citizen
          : false
        : false
    }
    return value;
  }

  _preload(value) {
    value.disabled = !value.disabled;
    return value;
  }

  _presubmit(value) {
    value.disabled = !value.disabled;
    value.label.en = value.label.en !== "" ? value.label.en : null;
    value.label.nl = value.label.nl !== "" ? value.label.nl : null;
    value.label.de = value.label.de !== "" ? value.label.de : null;
    if(!value.followers || (value.followers.length && value.redirectRule.discriminator !== "manager")){
      value.followers = [];
    }
    return value;
  }

  _searchTags = (label, alreadySelect) => {
    return this.props.api.service("issues", "tags").execute(Query.joinWithOptimizer(
        { $and: [{ name: { $regex: `.*${label}.*`, $options: 'i' }}, { name: { $nin: alreadySelect }}]},
        { tenant: this.props.currentTenant.currentId }
      ),
      { name: -1 },
      0,
      15
    ).then(tags => tags.map(t => t.name));
  }

  _renderTypeField = (category) => {
    const canManageBuilding   = this.props.acl.connectedUserIsAllow("buildings", "manage");
    const canManageEquipment  = this.props.acl.connectedUserIsAllow("equipments", "manage");
    if(!canManageBuilding && !canManageEquipment){
      return (<Input.Hidden name="type" value="publicSpace" />);
    }
    return (
      <Field>
        <Field.Label><T>type</T></Field.Label>
        <Field.Input>
        { 
          !category._id
          ? (
            <Input.Radio.Group name="type">
              <Input.Radio.Value value="publicSpace">{(selected) => (<Button.Radio pushed={selected}><T>public_space</T></Button.Radio>)}</Input.Radio.Value>
              { canManageBuilding && <Input.Radio.Value value="building">{(selected) => (<Button.Radio pushed={selected}><T>building</T></Button.Radio>)}</Input.Radio.Value> }
              { canManageEquipment && <Input.Radio.Value value="equipment">{(selected) => (<Button.Radio pushed={selected}><T>equipment</T></Button.Radio>)}</Input.Radio.Value> }
            </Input.Radio.Group>
          )
          : (
            <>
              <Input.Hidden name="type" />
              <div className='bs-form-category-type-readonly'><T>{category.type}</T></div>
              <span>&nbsp;</span>    
              <Nudge type='info'><T>category_form_type_readOnlyInfo</T></Nudge>     
            </>
          )
        }
        </Field.Input>
      </Field>
    );
  }

  _validateLabel(value, errors){
    if(!value.label?.fr || !value.label?.en || !value.label?.nl || !value.label?.de){
      errors.properties.push({
        path:"label",
        error: "required_input"
      });
    }
  }

  _validate = (value, errors) => {
    this._validateLabel(value, errors);
  }

  render() {
    const currentTenant = this.props.currentTenant;
    return (
      <Form.Model
        model={"Category"}
        observer={this.props.observer}
        onChange={this._onFormChange}
        id={this.props.id}
        ref={this.props.forwardedRef}
        preload={this._preload}
        validate={ this._validate }
        presubmit={this._presubmit}
      >
        {(category, errors) => {
          if (category.redirectRule && category.redirectRule.discriminator === "monoTenant" && !category.redirectRule.monoTenantLink) {
            category.redirectRule.monoTenantLink = [];
          }
          if (category.redirectRule && category.redirectRule.discriminator === "multiTenant" && !category.redirectRule.links) {
            category.redirectRule.links = [];
          }
          const defaultFollowersQuery = { 
            tenants: { 
              $elemMatch: { 
                tenant: category.tenant, 
                disabled: false 
              }
            }, 
            "authentificationProviders.0": { $exists: true } 
          };
          return (
            <FormError errors={errors}>
              <Input.Hidden name="tenant" default={ currentTenant.currentId } />
              <Input.Hidden name="_id" />
              <div className="bs-form-category">
                <FieldGroup type="full" title="name">
                  <Input.NewToOld name="label">
                    <NewInput.I18N />
                  </Input.NewToOld>                    
                </FieldGroup>
                <FieldGroup type="half" title="generals_informations">
                  { this._renderTypeField(category) }
                  <Display.If condition={category.type === "publicSpace" && currentTenant.discriminator === "town" }>
                    <Display.Then>
                      <Field>
                        <Field.Label><T>citizen_visibility</T></Field.Label>
                        <Field.Input><FInput.YesNo name="citizen" /></Field.Input>
                      </Field>
                      <Display.If condition={ !category.citizen }>
                        <Nudge type='warning'><T>category_form_visibility_warning</T></Nudge>
                      </Display.If>
                    </Display.Then>
                    <Display.Else>
                      <Input.Hidden name="citizen" />
                    </Display.Else>
                  </Display.If>
                  <Field>
                    <Field.Label><T>category_form_default_valorization</T></Field.Label>
                    <Field.Input><FInput.YesNo name="issuesValorizationMandatoryByDefault" data-testid="category_form_issuesValorizationMandatoryByDefault" /></Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>active_f</T></Field.Label>
                    <Field.Input><FInput.YesNo name="disabled" /></Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>category_form_tags</T></Field.Label>
                    <Field.Input>
                      <Input.NewToOld.Multiple name="tags">
                        <NewInput.Tag.Selectable dataSource={ this._searchTags } />
                      </Input.NewToOld.Multiple>
                    </Field.Input>
                  </Field>
                </FieldGroup>
                {this.renderRule(currentTenant, category)}
                <FieldGroup type="half" title="category_deadlineRules">
                  <Field>
                    <Field.Label><T>category_form_defaultDeadline</T></Field.Label>
                    <Field.Input>
                      <Input.NewToOld name="defaultDeadline">
                        <NewInput.Duration />
                      </Input.NewToOld>
                    </Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>category_form_deadlineReminder</T></Field.Label>
                    <Field.Input>
                      <Input.NewToOld name="deadlineReminder">
                        {(onChange, value) => (
                          <div className="bs-form_category-deadlineReminder-container">
                            <NewInput.Numeric min={1} max={ 31 } onChange={ onChange } value={ value } />
                            <div><T>category_form_deadlineReminder_input_days</T></div>
                          </div>
                        )}
                        
                      </Input.NewToOld>
                    </Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>category_form_deadlineReminderEmailAlert</T></Field.Label>
                    <Field.Input><FInput.YesNo name="deadlineReminderEmailAlert" /></Field.Input>
                  </Field>
                </FieldGroup>
                <Display.If condition={category.redirectRule && category.redirectRule.discriminator === "manager"}>
                  <FieldGroup type="half" title="category_followers">
                    <Field>
                      <Field.Label><T>category_default_followers</T></Field.Label>
                      <Field.Input>
                        <Input.Selectable.List name="followers" model={"User"} load={{ "tenants.tenant": true, avatar: true }} query={ defaultFollowersQuery } in={_in => _in ? _in.map(u => ({ _id: u})) : [] } out={_out => _out ? _out.map(u => u._id) : []}>
                          <Input.Selectable.List.Render textify={(user) => user.fullname} />
                          <Input.Selectable.List.Open>
                            {(open) => (<Button.Text onClick={open} ><T>select</T></Button.Text>)}
                          </Input.Selectable.List.Open>
                          <Input.Selectable.List.ListTitle>
                            <span><T>category_followers_select</T></span>
                          </Input.Selectable.List.ListTitle>
                          <Input.Selectable.List.Filter>
                            <div className="bs-role-modal-filter">
                              <Filter.Generic name="text" buildQuery={value => ({ fullname: { '$regex': value, '$options': 'i' } })} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                                {(value, set, clear) => (
                                  <NewInput.Text value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                                    <T>freesearch</T>
                                  </NewInput.Text>
                                )}
                              </Filter.Generic>
                            </div>
                          </Input.Selectable.List.Filter>
                          <Input.Selectable.List.Item>
                            <User.Item />
                          </Input.Selectable.List.Item>
                        </Input.Selectable.List>
                      </Field.Input>
                    </Field>
                  </FieldGroup>
                </Display.If>
              </div>
            </FormError>
          )
        }}
      </Form.Model>
    );
  }
  renderRule(currentTenant, category) {
    return (
      <Display.If condition={ currentTenant.isClient() } >
        {() => (
          <FieldGroup type="half" title="redirect_rule">
            <Input.Object name="redirectRule">
              <div className="bs-redirectRules">
                <Field>
                  <Field.Label><T>redirect_to_label</T></Field.Label>
                  <Field.Input >
                    <Display.If condition={ this.props.acl.connectedUserIsAllow("application", "administrate") || this.props.currentTenant.configurableTenants.length > 1 }>
                      <Display.Then>
                        <Input.Radio.Group name="discriminator">
                          <Input.Radio.Value value="manager">{(selected) => (<Button.Radio pushed={selected}><T>category_form_manager</T></Button.Radio>)}</Input.Radio.Value>
                          <Input.Radio.Value value="monoTenant">{(selected) => (<Button.Radio pushed={selected}><T>mono_tenant</T></Button.Radio>)}</Input.Radio.Value>
                          <Input.Radio.Value value="multiTenant">{(selected) => (<Button.Radio pushed={selected}><T>multi_tenant</T></Button.Radio>)}</Input.Radio.Value>
                        </Input.Radio.Group>
                      </Display.Then>
                      <Display.Else>
                        <Input.Radio.Group name="discriminator">
                          <Input.Radio.Value value="manager">{(selected) => (<Button.Radio pushed={selected}><T>category_form_manager</T></Button.Radio>)}</Input.Radio.Value>
                        </Input.Radio.Group>
                      </Display.Else>
                    </Display.If>
                  </Field.Input>
                </Field>
                <Display.If condition={category.redirectRule !== null}>
                  {() => (
                    <Display.Switch>
                      <Display.Case condition={category.redirectRule.discriminator === "manager"}>
                        {this.renderRuleManager(category)}
                      </Display.Case>
                      <Display.Case condition={category.redirectRule.discriminator === "monoTenant"}>
                        <Acl.If resource="categories" action="linkToExternalTenant">
                          <Acl.Then>{this.renderRuleTenant(currentTenant, category, false, "redirect_rule_monotenant_tenant", "category", 1)}</Acl.Then>
                          <Acl.Else>{this.renderRuleTenant(currentTenant, category, true, "redirect_rule_monotenant_tenant", "category", 1)}</Acl.Else>
                        </Acl.If>
                      </Display.Case>
                      <Display.Case condition={category.redirectRule.discriminator === "multiTenant"}>
                        <Acl.If resource="categories" action="linkToExternalTenant">
                          <Acl.Then>{this.renderRuleTenant(currentTenant, category, false, "redirect_rule_multitenant_tenant", "categories")}</Acl.Then>
                          <Acl.Else>{this.renderRuleTenant(currentTenant, category, true, "redirect_rule_multitenant_tenant", "categories")}</Acl.Else>
                        </Acl.If>
                      </Display.Case>
                    </Display.Switch>
                  )}
                </Display.If>
              </div>
            </Input.Object>
          </FieldGroup>
        )}
      </Display.If>
    )
  }
  renderRuleManager(category) {
    return (
      <>
        <Field>
          <Field.Label><T>redirect_rule_manager</T></Field.Label>
          <Field.Input>
            <Input.Selectable.One min={ 1 } name="manager" model={"User"} load={{ "tenants.tenant": true, avatar: true }} query={{ tenants: { $elemMatch: { disabled: false, tenant: category.tenant, roles: { $in: ['admin', 'manager', 'categoryManager'] }}}}} in={_in => _in ? { _id: _in } : null} out={_out => _out ? _out._id : null}>
              <Input.Selectable.One.Render textify={(user) => user.fullname} />
              <Input.Selectable.One.Open>
                {(open) => (<Button.Text onClick={open} ><T>select</T></Button.Text>)}
              </Input.Selectable.One.Open>
              <Input.Selectable.One.ListTitle>
                <span><T>category_form_manager</T></span>
              </Input.Selectable.One.ListTitle>
              <Input.Selectable.One.Filter>
                <div className="bs-role-modal-filter">
                  <Filter.Generic name="text" buildQuery={value => ({ fullname: { '$regex': value, '$options': 'i' } })} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                    {(value, set, clear) => (
                      <NewInput.Text value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                        <T>freesearch</T>
                      </NewInput.Text>
                    )}
                  </Filter.Generic>
                </div>
              </Input.Selectable.One.Filter>
              <Input.Selectable.One.Item>
                <User.Item />
              </Input.Selectable.One.Item>
            </Input.Selectable.One>
          </Field.Input>
        </Field>
        <Field>
          <Field.Label><T>redirect_rule_manager_transfer_to</T></Field.Label>
          <Field.Input>
            <Input.Selectable.One max={ 1 } name="defaultTransfer" model={"Contact"} query={{ disabled: false, tenant: category.tenant }} in={_in => _in ? { _id: _in } : null} out={_out => _out ? _out._id : null}>
              <Input.Selectable.One.Render textify={(contact) => contact.name} />
              <Input.Selectable.One.Open>
                {(open) => (<Button.Text onClick={open} ><T>select</T></Button.Text>)}
              </Input.Selectable.One.Open>
              <Input.Selectable.One.ListTitle>
                <span><T>category_form_default_transfer_list_title</T></span>
              </Input.Selectable.One.ListTitle>
              <Input.Selectable.One.Filter>
                <div className="bs-role-modal-filter">
                  <Filter.Generic name="text" buildQuery={value => ({ name: { '$regex': value, '$options': 'i' } })} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                    {(value, set, clear) => (
                      <NewInput.Text value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                        <T>freesearch</T>
                      </NewInput.Text>
                    )}
                  </Filter.Generic>
                </div>
              </Input.Selectable.One.Filter>
              <Input.Selectable.One.Item>
                <Contact.Item />
              </Input.Selectable.One.Item>
            </Input.Selectable.One>
          </Field.Input>
        </Field>
      </>
    )
  }
  renderRuleTenant(currentTenant, category, disabled, label, name, limit) {
    const _in = limit === 1
      ? (_in => _in ? [{ _id: _in }] : [])
      : (_in => _in ? _in.map(e => ({ _id: e })) : []);

    const _out = limit === 1
      ? (_out => _out && _out.length ? _out[0]._id : null)
      : (_out => _out && _out.length ? _out.map(e => e._id) : []);

    return (
      <Field>
        <Field.Label><T>{label}</T></Field.Label>
        <Field.Input>
          <Input.Selectable disabled={disabled} key={name} limit={limit} name={name} model={"Category"} load={{ "tenant": true }} query={{}} in={_in} out={_out}>
            <Input.Selectable.Render textify={(category) => ([(<T>{category.tenant.name}</T>), "-", (<T>{category.label}</T>)])} />
            <Input.Selectable.Open>
              {(open) => (<Button.Text onClick={open} ><T>select</T></Button.Text>)}
            </Input.Selectable.Open>
            <Input.Selectable.Means>
              {(validHandler, closeHandler, currentValues) => {
                let query = {};

                if(this.props.acl.connectedUserIsAllow("tenants", "manage") && currentTenant.changeMode === 'limited'){
                  query = { _id: { $in: currentTenant.configurableTenants.map(t => t._id) }}
                }

                const onFinish = (context) => {
                  validHandler([{ _id: context[1][0]._id }]);
                  closeHandler();
                };
                return React.createElement(Application.Service, { name: ["i18n"] }, ([i18n]) => (
                  <Wizard onFinish={onFinish} close={closeHandler} animate="slide" style={{ width: "40vw", 'height': "90vh" }}>
                    <Wizard.Step>
                      {(context, wizard) => {
                        return React.createElement(
                          Input.Selectable.ListSelector, {
                          model: "Tenant",
                          sort: { name: 1 },
                          load:  Tenant.Item.Load,
                          query: query,
                          title: (<T>redirect_rule_select_structure</T>),
                          limit: 1,
                          min: 1,
                          close: () => { closeHandler() },
                          add: (values) => wizard.next(values)
                        },
                          React.createElement(Input.Selectable.ListSelector.Filter, {}, (
                            <div key="category.tenant.name" className="bs-role-modal-filter">
                              <Filter.Generic name="text" buildQuery={value => ({ [i18n.queryProperty("name")]: { '$regex': value, '$options': 'i' } })} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                                {(value, set, clear) => (
                                  <NewInput.Text value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                                    <T>freesearch</T>
                                  </NewInput.Text>
                                )}
                              </Filter.Generic>
                            </div>
                          )),
                          React.createElement(Input.Selectable.ListSelector.Item, {}, React.createElement(Tenant.Item))
                        )
                      }}
                    </Wizard.Step>
                    <Wizard.Step>
                      {(context, wizard) => {
                        const query = { tenant: context[0][0]._id };
                        return React.createElement(Input.Selectable.ListSelector, {
                          model: "Category",
                          sort: {},
                          load: {},
                          query: query,
                          title: (<><T>redirect_rule_select_category_in_tenant</T>&nbsp;<T>{context[0][0].name}</T></>),
                          limit: 1,
                          min: 1,
                          close: () => closeHandler(),
                          add: (values) => wizard.next(values)
                        },
                          React.createElement(Input.Selectable.ListSelector.Filter, {}, (
                            <div key="category.category.label" className="bs-role-modal-filter">
                              <Filter.Generic name="text" buildQuery={value => ({ [i18n.queryProperty("label")]: { '$regex': value, '$options': 'i' } })} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                                {(value, set, clear) => (
                                  <NewInput.Text value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                                    <T>freesearch</T>
                                  </NewInput.Text>
                                )}
                              </Filter.Generic>
                            </div>
                          )),
                          React.createElement(Input.Selectable.ListSelector.Item, {}, React.createElement(CategoryItem))
                        )
                      }}
                    </Wizard.Step>
                  </Wizard>
                ));
              }}
            </Input.Selectable.Means>
          </Input.Selectable>
        </Field.Input>
      </Field>
    )
  }
}

export default Application.Service.forward(["acl", "currentTenant", "api"], React.forwardRef((props, ref) => (<CategoryForm {...props} forwardedRef={ref} />)));
