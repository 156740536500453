import { Helper }                 from "@uLib/application";
import AclService from "@universal/services/acl";
import CurrentTenantService from "@universal/services/currentTenant";
import SessionService from "@universal/services/session";
import ObjectId from "@universal/types/technic/ObjectId";

class QueryHelper extends Helper {
  constructor() {
    super('query', ["currentTenant", "acl", "session"]);
  }
  
  completeQueryWithBuildings(query: object, buildQueryHandler: (query: object, buildings: ObjectId[]) => object) {
    const aclService = this.application.getService<AclService>("acl");
    const currentTenantService = this.application.getService<CurrentTenantService>("currentTenant");
    const sessionService = this.application.getService<SessionService>("session");
    if (aclService.connectedUserIsAllow("buildings", "readAll")) {
      return query;
    } else {
      return buildQueryHandler(query, sessionService.user.tenants.find(t => t.tenant === currentTenantService.currentId).buildings);
    }
  }
}

export default QueryHelper;
